<template>
  <layout-auth>
    <validation-observer ref="vobserver">
      <validation-provider v-slot="props" name="__error_message__">
        <auth-form-base :message="props.errors.join()">
          <v-form v-if="!isTwoFA">
            <ValidationProvider v-slot="{ errors }" name="email">
              <v-text-field
                id="login-inp"
                v-model.trim="email"
                autocapitalize="off"
                prepend-icon="mdi-account"
                label="Login"
                :error-messages="errors"
                type="text"
              />
            </ValidationProvider>
            <v-text-field
              id="password-inp"
              v-model.trim="password"
              prepend-icon="mdi-lock"
              label="Password"
              type="password"
            />

            <v-btn
              id="login-btn"
              color="primary"
              class="mt-4 mb-3"
              :loading="isLoading"
              :disabled="isLoading"
              block
              depressed
              @click="submit"
            >
              Login
            </v-btn>
          </v-form>
          <v-form v-else>
            <v-otp-input
              ref="otpInput"
              v-model="otp"
              :title="twoFaInfo"
              autofocus
              length="6"
              type="number"
              @finish="isOTPDisabled = false"
            />

            <div class="subtitle-1">
              {{ twoFaHint }}
            </div>
            <v-btn
              class="mt-4 mb-3"
              block
              outlined
              type="click"
              color="primary"
              data-test="actionResendOtp"
              depressed
              :loading="isLoading"
              @click.prevent="submit"
            >
              Resend
            </v-btn>
            <v-btn
              class="mt-4 mb-3"
              block
              type="submit"
              color="primary"
              data-test="actionLogin"
              depressed
              :disabled="isOTPDisabled || otp.length < 6"
              :loading="isLoginViaOtp"
              @click.prevent="loginWithOtp"
            >
              Continue
            </v-btn>
          </v-form>
        </auth-form-base>
      </validation-provider>
    </validation-observer>
  </layout-auth>
</template>

<script>
import LayoutAuth from './AuthLayout';
import AuthFormBase from './AuthFormBase';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { extractServerErrors } from '@/utils/validation';

import { useAuth } from '@/modules/auth/api';
import { ref } from '@vue/composition-api';
export default {
  name: 'AuthFormLogin',
  components: {
    LayoutAuth,
    AuthFormBase,
    ValidationObserver,
    ValidationProvider
  },
  setup(props, context) {
    const email = ref('');
    const password = ref('');
    const errorMessage = ref('');
    const isLoading = ref(false);
    const otp = ref('');
    const twoFaHint = ref('');
    const twoFaInfo = ref('');
    const isLoginViaOtp = ref(false);
    const isTwoFA = ref(false);
    const isOTPDisabled = ref(true);
    const { login, submitOTP } = new useAuth(context);

    const loginWithOtp = async () => {
      if (!isLoading.value) {
        errorMessage.value = '';
        isLoginViaOtp.value = true;
        try {
          await submitOTP(otp.value);
          const last = localStorage.getItem('lastUrlPathname');
          localStorage.removeItem('lastUrlPathname');
          if (last && !last.includes('login')) {
            context.root.$router.push(last);
          } else {
            context.root.$router.push({ name: 'objects' });
          }
        } catch (e) {
          context.refs.vobserver.setErrors(
            extractServerErrors(e, ['email', 'password'])
          );
        } finally {
          isLoginViaOtp.value = false;
        }
      }
    };

    const submit = async () => {
      try {
        isLoading.value = true;
        const loginData = await login({
          userLogin: email.value,
          userPassword: password.value
        });

        if (loginData?.type) {
          twoFaHint.value = `OTP-code has been sent by ${loginData?.type}`;
          twoFaInfo.value = `${loginData?.info}-XXXXXX`;

          isTwoFA.value = true;
        } else {
          const last = localStorage.getItem('lastUrlPathname');
          localStorage.removeItem('lastUrlPathname');
          if (last && !last.includes('login')) {
            context.root.$router.push(last);
          } else {
            context.root.$router.push({ name: 'objects' });
          }
        }
      } catch (e) {
        context.refs.vobserver.setErrors(
          extractServerErrors(e, ['email', 'password'])
        );
      } finally {
        isLoading.value = false;
      }
    };

    return {
      email,
      password,
      errorMessage,
      submit,
      isLoading,
      otp,
      twoFaHint,
      twoFaInfo,
      isLoginViaOtp,
      isTwoFA,
      isOTPDisabled,
      loginWithOtp
    };
  }
};
</script>
